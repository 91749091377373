import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Destinations from "../components/destinations";
import Banner from "../components/banner";
import ImageWithText from "../components/image-with-text";

const DestinationsPage = ({ location, data }) => {
  const { formatMessage } = useIntl();
  const { header } = data;
  const { banner, seoSection } = data.home;

  return (
    <Layout whiteHeader>
      <Seo title="Destinations" />
      <Hero title={header.imageAlt} media={header.image} reviews={true} />
      <Banner
        heading={banner.heading}
        text={banner.text}
        link={banner.link}
        linkText={banner.linkText}
        buttonLink={banner?.buttonLink}
      />
      <Destinations
        heading={formatMessage({ id: "destinationsChoose" })}
        intro={formatMessage({ id: "destinationsIntro" })}
        tabsOnDesktop
      />
      <ImageWithText
        image={seoSection?.image}
        mobileImage={seoSection?.mobileImage}
        imageAlt={seoSection?.imageAlt}
        text={seoSection?.text}
      />
    </Layout>
  );
};

export default DestinationsPage;

export const query = graphql`
  query ($locale: String!) {
    video: file(relativePath: { eq: "destinations-video.mp4" }) {
      publicURL
    }

    videoPoster: file(relativePath: { eq: "destinations-video-poster.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    home: contentfulHome(
      node_locale: { eq: $locale }
      contentful_id: { eq: "62rvf78k5yrgR3aq2foZ58" }
    ) {
      banner {
        heading
        text {
          raw
        }
        link
        linkText
        buttonLink {
          id
          button
          buttonBackgroundColour
          textColour
          title
          link
          target
        }
      }
      seoSection {
        image {
          description
          title
          gatsbyImageData(quality: 80)
        }
        mobileImage {
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
      }
    }

    header: contentfulImageWithText(
      metadata: { tags: { elemMatch: { name: { eq: "headerDestinations" } } } }
      node_locale: { eq: $locale }
    ) {
      imageAlt
      image {
        gatsbyImageData(quality: 80)
        description
        title
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
